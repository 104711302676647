//For Base URL and User Authentication
const BASE_URL = "https://api.automa.id/api";

const TRANSPORTER_URL = BASE_URL + '/transporter'
const REPORT_BASE_URL = TRANSPORTER_URL + '/report'
const TRANSPORTER_SHARE_TRACK_URL = REPORT_BASE_URL + '/check/in/log/share'
const TRANSPORTER_DELIVERY_CONFIRMATION_DETAILS_URL = BASE_URL + '/delivery/work/customer/view'
const TRANSPORTER_DELIVERY_CONFIRMATION_APPROVE_URL = BASE_URL + '/delivery/customer/approve'

const TRIPL_CUSTOMER_ENTRY_DATA = BASE_URL + '/delivery/customer/non/add'
const TRIPL_WO_ENTRY_DATA = BASE_URL + '/delivery/work/non/request/add'

const DELIVERY_VALIDATION_WO_URL = BASE_URL + '/delivery/customer/non/validation'

const DELIVERY_COMPANY_URL = BASE_URL + '/delivery/company'

const DELIVERY_BASE_URL = BASE_URL + '/delivery';
const DELIVERY_STATUS_LIST_URL = DELIVERY_BASE_URL + '/status/list'

const TRIPL_COMPANY_GET_URL = BASE_URL + '/3pl/get'
const TRIPL_COMPANY_SUBMIT_URL = BASE_URL + '/3pl/submit'

const TRIPL_FIRST_PAYMENT_URL = BASE_URL + '/delivery/customer/non/view/bid'
const TRIPL_PAYMENT_GENERATE_URL = BASE_URL + '/delivery/customer/non/create/payment'
const TRIPL_CHECK_AMOUNT_PAYMENT_URL = BASE_URL
const TRIPL_CHECK_PAYMENT_URL = BASE_URL

export {
    TRANSPORTER_SHARE_TRACK_URL,
    TRANSPORTER_DELIVERY_CONFIRMATION_DETAILS_URL,
    TRANSPORTER_DELIVERY_CONFIRMATION_APPROVE_URL,

    TRIPL_CUSTOMER_ENTRY_DATA,
    TRIPL_WO_ENTRY_DATA,
    DELIVERY_VALIDATION_WO_URL,

    DELIVERY_COMPANY_URL,

    TRIPL_COMPANY_GET_URL,
    TRIPL_COMPANY_SUBMIT_URL,

    DELIVERY_STATUS_LIST_URL,

    TRIPL_FIRST_PAYMENT_URL,
    TRIPL_PAYMENT_GENERATE_URL,
    TRIPL_CHECK_AMOUNT_PAYMENT_URL,
    TRIPL_CHECK_PAYMENT_URL
    
}