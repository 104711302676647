import axios from "axios";
import * as API_LINKS from '../config/links.jsx'
import {toast} from 'react-toastify'

export const FETCH_TRANSPORTER_SHARE_TRACK = 'FETCH_TRANSPORTER_SHARE_TRACK'
export const RECEIVE_TRANSPORTER_SHARE_TRACK = 'RECEIVE_TRANSPORTER_SHARE_TRACK'
export const FAILED_TRANSPORTER_SHARE_TRACK = 'FAILED_TRANSPORTER_SHARE_TRACK'

export function fetchTransporterShareTrack(_id){

    return (dispatch, getState) => {
        dispatch({type: FETCH_TRANSPORTER_SHARE_TRACK});
        axios({
            method: 'post',
            url: API_LINKS.TRANSPORTER_SHARE_TRACK_URL,
            data: {
                share_data: _id
            }
        })
        .then((response) => {
            dispatch( {
                type: RECEIVE_TRANSPORTER_SHARE_TRACK,
                payload: [response.data]
            })
            console.log(response.data)
        })
        .catch(function(error){
            if(error.response){
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_TRANSPORTER_SHARE_TRACK
                    })
                    return toast.error("Unathorized access!");
                    
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_TRANSPORTER_SHARE_TRACK
                    })
                    return toast.error("Failed in fetching Delivery Orders List. Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_TRANSPORTER_SHARE_TRACK
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_TRANSPORTER_SHARE_TRACK
                    })
                    return toast.error("Please contact your system adminitrator")
                }
            }else if (error.request) {
                dispatch({
                    type: FAILED_TRANSPORTER_SHARE_TRACK
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_TRANSPORTER_SHARE_TRACK
                })
                return toast.error('Something went wrong... Please try again later...')
            }

        })
    }
}