const targetTypeSelections = [
    {
        'label': '- Please select target type -',
        'value': 0
    },
    {
        'label': 'Pickup by date',
        'value': 1
    },
    {
        'label': 'Arrived at destination by date',
        'value': 2
    }
]

export {
    targetTypeSelections
}