import React, { Component } from 'react';
import {Card, Container, Row, Col, CardGroup, CardBody, Alert } from 'reactstrap';
import logoAutoma from '../../assets/img/brand/new-automa-logo.png'

class Lost extends Component {
    constructor(props) {
        super(props);
        this.state = { 

         };
    }

    updateYear = () => {
        var d = new Date();
        var n = d.getFullYear();
        return n;
    }

    render() {
        return (
            <div>
                <div className="app flex-row align-items-center login-body"> 
                    <Container>
                        <Row className="justify-content-center">
                        <Col md="9">
                            <CardGroup>
                                <Card className="p-4">
                                    <CardBody>
                                        <img src={logoAutoma ? logoAutoma : logoAutoma} alt="Company Logo" className="automa-logo-login" style={{'maxWidth': '2em', 'height': 'auto'}}/>
                                        <br/><br/>
                                        <br/>

                                        <Alert color="warning">
                                            It seems you're lost on the site. We'd like to refer you to our super IoT platform <strong><a href="https://automa.id">automa.id</a></strong>
                                        </Alert>
                                        {/* {
                                            this.props.match.params.id ? 
                                                this.props.inProgress ? 
                                                <center><HashLoader color={'#123abc'} loading={this.props.inProgress}/> <br /> Loading.... Please wait...</center>
                                                : 
                                                (
                                                    this.props.success  ? 
                                                    this.checkDataShow() 
                                                    : 
                                                    <center>Error fetching your data... <br/><br/> 
                                                        <Button className="btn btn-primary" color="primary" size="md" disabled={this.props.inProgress} onClick={() => this.props.fetchTransporterShareTrack(this.props.match.params.id)}>
                                                            <i className="fa fa-refresh"></i> 
                                                            Try again
                                                        </Button>
                                                    </center>
                                                )
                                            :
                                            <Alert color="success">
                                                Your package delivery tracking link already obsolete. Please contact your supplier/logistic to verify delivery status. Thank you for trusting Automa ID as your one and only only Supply Chain and Logistics Management Solution.
                                            </Alert>
                                        } */}
                                    </CardBody>
                                    <Row>
                                        <Col md="12">
                                            <span className="pull-right">Always Powered by <a href="https://automa.id">Automa</a> &copy; {this.updateYear()}</span>
                                        </Col>
                                    </Row>
                                    
                                    </Card>
                                </CardGroup>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        );
    }
}

export default Lost;