import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Card, Container, Row, Col, CardGroup, CardBody, Button, Alert } from 'reactstrap';
import logoAutoma from '../../assets/img/brand/new-automa-logo.png'
import CompanyFillOut from './CompanyFillOut';
import { HashLoader } from 'react-spinners';
import axios from 'axios';
import { fetchCompany } from '../../redux/actions/company.actions'

class Company extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            ip: "0.0.0.0"
         };
    }

    componentDidMount = () => {
        this.props.fetchCompany(this.props.match.params.id)
        this.getIpInfo()
    }

    updateYear = () => {
        var d = new Date();
        var n = d.getFullYear();
        return n;
    }

    getIpInfo = () => {
        let self = this;
        self.setState({
            loading: true,
        });

        axios.get("https://api.ipify.org/?format=json").then(response => {
            let data = response.data || {};
            self.setState({
                ip: data.ip || "",
                // country: data.country || "",
                loading: false,
            });


        }).catch((err) => {
            self.setState({
                loading: false,
            });
        });
    };

    showContent = () => {
        const { companyName } = this.props;
        const { ip } = this.state;
        // const { id } = this.props.match.params;
        return (
            <div>
                <Alert color="warning">
                    You are about to access {companyName ? companyName : 'Automa'} 3PL Trucking System. Please input your identification first. You'll receive a magic link to access the system via Whatsapp. <br/><strong>Please proceed with precaution! Anthing that has been inputted to the system can't be canceled or denied!</strong>
                </Alert>
                <CompanyFillOut 
                    id={this.props.match.params.id}
                    companyName={companyName}
                    ip={ip}
                />
            </div>
        )
    }

    render() {
        const { companyName, companyImage, inProgress, success } = this.props;
        const { id } = this.props.match.params
        return (
            <div>
                <div className="app flex-row align-items-center login-body"> 
                    <Container>
                        <Row className="justify-content-center">
                        <Col md="9">
                            <CardGroup>
                                <Card className="p-4">
                                    <CardBody>
                                        <img src={companyImage ? companyImage : logoAutoma} alt="Company Logo" className="automa-logo-login" style={{'maxWidth': '1.5em', 'height': 'auto', 'marginBottom': '2.5em'}}/>
                                        <br style={{'marginBottom': '2em'}}/><br style={{'marginBottom': '4em'}}/><br/>
                                        <h4 style={{'marginTop': '2em'}}>{companyName ? companyName : 'Automa'} 3PL Website</h4><br/>
                                        {
                                            id ? 
                                            <div>
                                                {
                                                    inProgress ? 
                                                    <center><HashLoader color={'#123abc'} loading={inProgress}/> <br /> Loading.... Please wait...</center>
                                                    :
                                                    (
                                                        success ? 
                                                        (
                                                            companyName ? 
                                                            <div>
                                                                {this.showContent()}
                                                            </div>
                                                            :
                                                            <Alert color="warning">
                                                                It seems you're lost on the site. We'd like to refer you to our super IoT platform <strong><a href="https://automa.id">automa.id</a></strong>
                                                            </Alert>
                                                        )
                                                        :
                                                        <center>Error fetching your data... <br/><br/> 
                                                            <Button className="btn btn-primary" color="primary" size="md" disabled={inProgress} onClick={() => this.props.fetchCompany(id)}>
                                                                <i className="fa fa-refresh"></i> 
                                                                Try again
                                                            </Button>
                                                        </center>
                                                    )
                                                }
                                            </div>
                                            :
                                            <Alert color="warning">
                                                It seems you're lost on the site. We'd like to refer you to our super IoT platform <strong><a href="https://automa.id">automa.id</a></strong>
                                            </Alert>
                                        }
                                        {/* {
                                            this.props.match.params.id ? 
                                                this.props.inProgress ? 
                                                <center><HashLoader color={'#123abc'} loading={this.props.inProgress}/> <br /> Loading.... Please wait...</center>
                                                : 
                                                (
                                                    this.props.success  ? 
                                                    this.checkDataShow() 
                                                    : 
                                                    <center>Error fetching your data... <br/><br/> 
                                                        <Button className="btn btn-primary" color="primary" size="md" disabled={this.props.inProgress} onClick={() => this.props.fetchTransporterShareTrack(this.props.match.params.id)}>
                                                            <i className="fa fa-refresh"></i> 
                                                            Try again
                                                        </Button>
                                                    </center>
                                                )
                                            :
                                            <Alert color="success">
                                                Your package delivery tracking link already obsolete. Please contact your supplier/logistic to verify delivery status. Thank you for trusting Automa ID as your one and only only Supply Chain and Logistics Management Solution.
                                            </Alert>
                                        } */}
                                    </CardBody>
                                    <Row>
                                        <Col md="12">
                                            <span className="pull-right">Always Powered by <a href="https://automa.id">Automa</a> &copy; {this.updateYear()}</span>
                                        </Col>
                                    </Row>
                                    
                                    </Card>
                                </CardGroup>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        success: state.deliveryCompanyStore.success,
        inProgress: state.deliveryCompanyStore.inProgress,
        companyName: state.deliveryCompanyStore.companyName,
        companyImage: state.deliveryCompanyStore.companyImage,
        deliveryCompanyData: state.deliveryCompanyStore.deliveryCompanyData
    }
}

export default connect(mapStateToProps, { fetchCompany })(Company);