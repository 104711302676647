import React, { Component } from 'react';
import {
    Button,
    FormGroup,
    Col,
    Label,
    Input,
    FormFeedback,
    Alert
} from 'reactstrap'
import * as Yup from 'yup'
import { BarLoader } from 'react-spinners';
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import axios from 'axios'
import {toast} from 'react-toastify'
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
    // InfoWindow, 
    KmlLayer
  } from "react-google-maps"
import { compose } from "recompose"
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import moment from 'moment';
import { SelectField } from '../Widgets/SelectField'
import { TRIPL_WO_ENTRY_DATA } from '../../redux/config/links';
import { targetTypeSelections } from '../../constants/constants';

const propTypes = {
    validation_key: PropTypes.string,
    id_customer: PropTypes.number,
    company_lat: PropTypes.number,
    company_lng: PropTypes.number,
    ip_address: PropTypes.string,
}

const defaultProps = {
    validation_key: '',
    id_customer: 0,
    company_lat: 0,
    company_lng: 0,
    ip_address: "0.0.0.0"
}

const validationSchema = Yup.object().shape({
    wo_number: Yup.string()
    .nullable()
    .required('WO Number should be stated!'),
    wo_desc: Yup.string()
    .nullable()
    .notRequired(),
    wo_destination_name: Yup.string()
    .nullable()
    .required('WO Destination Name should be stated!'),
    wo_address: Yup.string()
    .nullable()
    .required('WO Address should be stated!'),
    company_name: Yup.string()
    .nullable()
    .min(5, 'Destination Company Name should not be shorter than 5 characters!')
    .required('Destination Company Name should be stated!'),
    pic_name: Yup.string()
    .nullable()
    .required('PIC Name should be stated!'),
    pic_phone: Yup.string()
    .nullable()
    .min(7, 'PIC Phone Number should not be shorter than 7 characters!')
    .matches(/^[6]{1}[2]{1}[0-9]{6}/, 'Phone number should be start with 62')
    .required('PIC phone number should be stated!'),
    dest_lat: Yup.number()
    .nullable()
    .required('Destination Latitude is required!'),
    dest_lng: Yup.number()
    .nullable()
    .required('Destination Logitude is required!'),
    // loc_name: Yup.object()
    // .shape({
    //   lat: Yup.number(),
    //   lng: Yup.number()
    // })
    // .nullable()
    // .required("Location is required"),
    cargo_weight: Yup.number()
    .nullable()
    .required('Cargo weight should be stated!')
    .min(1, 'Cargo weight cannot be zero or lower!'),
    box_length: Yup.number()
    .nullable()
    .required('Box length should be stated!')
    .min(1, 'Box length cannot be zero or lower!'),
    box_height: Yup.number()
    .nullable()
    .required('Box height should be stated!')
    .min(1, 'Box height cannot be zero or lower!'),
    box_width: Yup.number()
    .nullable()
    .required('Box width should be stated!')
    .min(1, 'Box width cannot be zero or lower!'),
    box_desc: Yup.string()
    .nullable()
    .required('Box description should be stated!'),
    // dept_loc_name: Yup.object()
    // .shape({
    //   lat: Yup.number(),
    //   lng: Yup.number()
    // })
    // .nullable()
    // .required("Departure location is required"),
    dept_loc_name: Yup.string()
    .nullable()
    .min(5, 'Departure location name cannot be shorter than 5 characters')
    .required('Departure Location Name should be stated!'),
    dept_lat: Yup.number()
    .nullable()
    .required('Departure Latitude is required!'),
    dept_lng: Yup.number()
    .nullable()
    .required('Departure Logitude is required!'),
    target_date: Yup.string()
    .nullable()
    .required('Installation date should be stated!'),
    target_type: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.number()
    })
    .nullable()
    .required("Target type is required"),
})

function generateRandom() {
    return Math.random() * 10000000000000000
}

const MapWithAMarker = compose(withScriptjs, withGoogleMap)(props => {
    return (
      <GoogleMap defaultZoom={16} 
        defaultCenter={{ lat: (props.lat ? props.lat : props.company_lat), lng: (props.lng ? props.lng : props.company_lng) }} 
        center={{ lat: (props.lat ? props.lat : props.company_lat), lng: (props.lng ? props.lng : props.company_lng) }}
        onClick={props.onClick}
        
        >
            {console.log(props)}
          {/* {console.log(props.markers)} */}
          <KmlLayer url={props.kmlLink + "&ver=" + generateRandom()} options={{ preserveViewport: true }}/>
          {/* {props.marker === []? */}
            {/* true: */}

            <Marker
            key={1}
            // onClick={e => props.onClick(e)}
            //   options={{icon: customMarker}}
            position={{ lat: props.lat, lng: props.lng }}
          >
              {/* {props.selectedMarker === marker &&
            <InfoWindow>
              <div>
                {marker.label}
              </div>
            </InfoWindow>
          } */}
          </Marker>
            {/* } */}
      </GoogleMap>
    )
})

class WorkOrderForm extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            initialState: {
                wo_number: null,
                wo_desc: null,
                wo_destination_name: null,
                wo_address: null,
                company_name: null,
                pic_name: null,
                pic_phone: null,
                // loc_name: {
                //     lat: null,
                //     lng: null,
                // },
                dest_lat: null,
                dest_lng: null,
                cargo_weight: null,
                box_length: null,
                box_height: null,
                box_width: null,
                box_desc: null,
                dept_loc_name: null,
                dept_lat: null,
                dept_lng: null,
                target_date: '',
                target_type: 0
            },
        };
    }

    handleDepartureDateChange = (_date) => {
        var convertedDate = moment(_date).format("MM-DD-YYYY HH:mm:ss")
        // var googleDate = new Date(_date);
        this.setState({
            rawDepartureDate: _date,
            departureDate: convertedDate
        })
        // this.handleDistanceCalculation(googleDate, convertedDate); // for distance calculation
    }

    handleClickMap = event => { 
        const lat = event.latLng.lat; 
        const lng = event.latLng.lng; 
        console.log('onClick map: ', lat, lng); 
    }

    viewAddWOReq = () => {
        var yesterday = moment().subtract( 1, 'day' );
        var disabledDays = function( current ){
            return current.isAfter( yesterday );
        };
        const { id_customer, ip_address } = this.props
        return (
            <Formik
                initialValues={this.state.initialState}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    var self = this;
                    // var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
                    // var auth = loginCreds.token;
                    setSubmitting(true)

                    axios({
                        method: 'post',
                        url: TRIPL_WO_ENTRY_DATA,
                        headers: {
                            // Authorization: auth
                        },
                        data: {
                            id: id_customer,
                            wo_number: values.wo_number,
                            wo_desc: values.wo_desc,
                            wo_destination_name: values.wo_destination_name,
                            wo_address: values.wo_address,
                            name: values.company_name,
                            pic_name: values.pic_name,
                            pic_phone: values.pic_phone,
                            loc_name: values.wo_destination_name,
                            lat: values.dest_lat,
                            lng: values.dest_lng,
                            cargo_weight: values.cargo_weight,
                            box_height: values.box_height,
                            box_length: values.box_length,
                            box_width: values.box_width,
                            box_desc: values.box_desc,
                            wo_from_name: values.dept_loc_name,
                            dept_lat: values.dept_lat,
                            dept_lng: values.dept_lng,
                            target_type: values.target_type.value,
                            target_time: values.target_date._d,
                            ip_address: ip_address
                        }
                    })
                    .then((response) => {
                        if(response.status === 200){
                            if (response.data.status === "success"){
                                self.setState({
                                    submitted: true
                                })
                                setSubmitting(false)
                                toast.success('WO Request has been saved succesfully! Please wait while the company assess your request...')
                                // self.toggleCloseAdd();
                                resetForm()
                                // self.props.fetchTriPLWorkOrderRequestResult()
                            }
                        }
                    })
                    .catch(function(error){
                        if (error.response) {
                            if(error.response.status === 401) {
                                setSubmitting(false)
                                return toast.error("Invalid credentials! Please try again!");
                            } else if (error.response.status === 403) {
                                setSubmitting(false)
                                return toast.error("Unauthorized Access! Please try to logout..");
                            } else if (error.response.status === 400) {
                                setSubmitting(false)
                                return toast.error(error.response.data.message);
                            } else if (error.response.status === 404 || error.response.status === 500) {
                                setSubmitting(false)
                                return toast.error("Server cannot be contacted! Please ask your system administrator!");
                            } else {
                                setSubmitting(false)
                                return toast.error('Something went wrong... Please try again later...')
                            }
                        } else if (error.request) {
                            setSubmitting(false)
                            return toast.error('Request error! Please try again later...')
                        } else {
                            setSubmitting(false)
                            return toast.error('Something went wrong... Please try again later...')
                        }
                    })
                }}
                validationSchema={validationSchema}
                render={({
                values,
                errors,
                touched,
                setFieldValue,
                setValues,
                setFieldTouched,
                handleSubmit,
                handleBlur,
                handleChange,
                handleReset,
                setTouched,
                isValid,
                isSubmitting
                }) => (
                <form>
                    <FormGroup row>
                        <Col md="3">
                            <Label htmlFor="text-input">WO Number</Label>
                        </Col>
                        <Col xs="12" md="9">
                            <Input type="text"
                                    name="wo_number"
                                    id="wo_number"
                                    placeholder="Work Order Number"
                                    // autoComplete="work-order-number"
                                    valid={!errors.wo_number}
                                    invalid={touched.wo_number && !!errors.wo_number}
                                    autoFocus={true}
                                    required
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.wo_number} />
                            <FormFeedback>{errors.wo_number}</FormFeedback>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col md="3">
                            <Label htmlFor="text-input">WO Desc</Label>
                        </Col>
                        <Col xs="12" md="9">
                            <Input type="textarea"
                                    name="wo_desc"
                                    id="wo_desc"
                                    placeholder="Work Order Description"
                                    // autoComplete="work-order-desc"
                                    valid={!errors.wo_desc}
                                    invalid={touched.wo_desc && !!errors.wo_desc}
                                    // autoFocus={true}
                                    required
                                    rows="10"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.wo_desc} />
                            <FormFeedback>{errors.wo_desc}</FormFeedback>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col md="3">
                            <Label htmlFor="text-input">Destination Name</Label>
                        </Col>
                        <Col xs="12" md="9">
                            <Input type="text"
                                    name="wo_destination_name"
                                    id="wo_destination_name"
                                    placeholder="WO Destination Name"
                                    // autoComplete="wo-dest-name"
                                    valid={!errors.wo_destination_name}
                                    invalid={touched.wo_destination_name && !!errors.wo_destination_name}
                                    // autoFocus={true}
                                    required
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.wo_destination_name} />
                            <FormFeedback>{errors.wo_destination_name}</FormFeedback>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col md="3">
                            <Label htmlFor="text-input">WO Address</Label>
                        </Col>
                        <Col xs="12" md="9">
                            <Input type="textarea"
                                    name="wo_address"
                                    id="wo_address"
                                    placeholder="Work Order Address"
                                    // autoComplete="work-order-address"
                                    valid={!errors.wo_address}
                                    invalid={touched.wo_address && !!errors.wo_address}
                                    // autoFocus={true}
                                    required
                                    rows="10"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.wo_address} />
                            <FormFeedback>{errors.wo_address}</FormFeedback>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col md="3">
                            <Label htmlFor="text-input">Receiver Company</Label>
                        </Col>
                        <Col xs="12" md="9">
                            <Input type="text"
                                    name="company_name"
                                    id="company_name"
                                    placeholder="Company Name"
                                    // autoComplete="company-name"
                                    valid={!errors.company_name}
                                    invalid={touched.company_name && !!errors.company_name}
                                    // autoFocus={true}
                                    required
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.company_name} />
                            <FormFeedback>{errors.company_name}</FormFeedback>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col md="3">
                            <Label htmlFor="text-input">Receiver Name</Label>
                        </Col>
                        <Col xs="12" md="9">
                            <Input type="text"
                                    name="pic_name"
                                    id="pic_name"
                                    placeholder="Receiver Name"
                                    // autoComplete="pic-name"
                                    valid={!errors.pic_name}
                                    invalid={touched.pic_name && !!errors.pic_name}
                                    // autoFocus={true}
                                    required
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.pic_name} />
                            <FormFeedback>{errors.pic_name}</FormFeedback>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col md="3">
                            <Label htmlFor="text-input">Receiver Phone (Whatsapp)</Label>
                        </Col>
                        <Col xs="12" md="9">
                            <Input type="text"
                                    name="pic_phone"
                                    id="pic_phone"
                                    placeholder="Receiver Phone ex 628xxx"
                                    // autoComplete="pic-name"
                                    valid={!errors.pic_phone}
                                    invalid={touched.pic_phone && !!errors.pic_phone}
                                    // autoFocus={true}
                                    required
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.pic_phone} />
                            <FormFeedback>{errors.pic_phone}</FormFeedback>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col md="3">
                            <Label htmlFor="text-input">Destination Latitude</Label>
                        </Col>
                        <Col xs="12" md="9">
                            <Input type="number"
                                    name="dest_lat"
                                    id="dest_lat"
                                    step="0.00001"
                                    placeholder="Destination Latitude"
                                    valid={!errors.dest_lat}
                                    invalid={touched.dest_lat && !!errors.dest_lat}
                                    // autoFocus={true}
                                    required
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.dest_lat} />
                            <FormFeedback>{errors.dest_lat}</FormFeedback>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col md="3">
                            <Label htmlFor="text-input">Destination Longitude</Label>
                        </Col>
                        <Col xs="12" md="9">
                            <Input type="number"
                                    name="dest_lng"
                                    id="dest_lng"
                                    step="0.00001"
                                    placeholder="Destination Longitude"
                                    valid={!errors.dest_lng}
                                    invalid={touched.dest_lng && !!errors.dest_lng}
                                    // autoFocus={true}
                                    required
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.dest_lng} />
                            <FormFeedback>{errors.dest_lng}</FormFeedback>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col md="3">
                            <Label htmlFor="text-input">Destination Plot</Label>
                        </Col>
                        <Col xs="12" md="9">
                            <div className="text-muted">Click on the maps to set destination<br/></div>
                            <MapWithAMarker
                                // selectedMarker={this.state.selectedMarker}
                                // markers={this.state.markers}
                                lat={values.dest_lat}
                                lng={values.dest_lng}
                                company_lat={this.props.company_lat}
                                company_lng={this.props.company_lng}
                                // onClick={e => {
                                //     var lat = e.latLng.lat() 
                                //     var lng = e.latLng.lng()
                                //     setFieldValue("dest_lat", lat)
                                //     setFieldValue("dest_lng", lng)
                                //     console.log(lat, lng)
                                // }}
                                // onClick={(e => this.handleClickMap(e))}
                                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCmIATqobqbCEU90nYtwd-PzCcj8EVOlDg&v=3.exp&libraries=geometry,drawing,places"
                                loadingElement={<div style={{ height: `100%` }} />}
                                containerElement={<div style={{ height: `250px` }} />}
                                mapElement={<div style={{ height: `100%` }} />}
                                kmlLink={ this.props.statKML? this.props.kml : ''}
                            />
                            <FormFeedback>{errors.dest_lat + ' and ' + errors.dest_lng}</FormFeedback>
                        </Col>
                </FormGroup>
                    <FormGroup row>
                        <Col md="3">
                            <Label htmlFor="text-input">Cargo Weight (in Kg)</Label>
                        </Col>
                        <Col xs="12" md="9">
                            <Input type="number"
                                    name="cargo_weight"
                                    id="cargo_weight"
                                    placeholder="Cargo Weight"
                                    valid={!errors.cargo_weight}
                                    invalid={touched.cargo_weight && !!errors.cargo_weight}
                                    // autoFocus={true}
                                    required
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.cargo_weight} />
                            <FormFeedback>{errors.cargo_weight}</FormFeedback>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col md="3">
                            <Label htmlFor="text-input">Box Length</Label>
                        </Col>
                        <Col xs="12" md="9">
                            <Input type="number"
                                    name="box_length"
                                    id="box_length"
                                    placeholder="Box Length"
                                    valid={!errors.box_length}
                                    invalid={touched.box_length && !!errors.box_length}
                                    // autoFocus={true}
                                    required
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.box_length} />
                            <FormFeedback>{errors.box_length}</FormFeedback>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col md="3">
                            <Label htmlFor="text-input">Box Width</Label>
                        </Col>
                        <Col xs="12" md="9">
                            <Input type="number"
                                    name="box_width"
                                    id="box_width"
                                    placeholder="Box Width"
                                    valid={!errors.box_width}
                                    invalid={touched.box_width && !!errors.box_width}
                                    // autoFocus={true}
                                    required
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.box_width} />
                            <FormFeedback>{errors.box_width}</FormFeedback>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col md="3">
                            <Label htmlFor="text-input">Box Height</Label>
                        </Col>
                        <Col xs="12" md="9">
                            <Input type="number"
                                    name="box_height"
                                    id="box_height"
                                    placeholder="Box Height"
                                    valid={!errors.box_height}
                                    invalid={touched.box_height && !!errors.box_height}
                                    // autoFocus={true}
                                    required
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.box_height} />
                            <FormFeedback>{errors.box_height}</FormFeedback>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col md="3">
                            <Label htmlFor="text-input">Box Desc</Label>
                        </Col>
                        <Col xs="12" md="9">
                            <Input type="textarea"
                                    name="box_desc"
                                    id="box_desc"
                                    placeholder="Box Description"
                                    valid={!errors.box_desc}
                                    invalid={touched.box_desc && !!errors.box_desc}
                                    // autoFocus={true}
                                    required
                                    rows="5"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.box_desc} />
                            <FormFeedback>{errors.box_desc}</FormFeedback>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col md="3">
                            <Label htmlFor="text-input">Departure Location Name</Label>
                        </Col>
                        <Col xs="12" md="9">
                            <Input type="text"
                                    name="dept_loc_name"
                                    id="dept_loc_name"
                                    placeholder="Departure Location Name"
                                    // autoComplete="wo-dest-name"
                                    valid={!errors.dept_loc_name}
                                    invalid={touched.dept_loc_name && !!errors.dept_loc_name}
                                    // autoFocus={true}
                                    required
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.dept_loc_name} />
                            <FormFeedback>{errors.dept_loc_name}</FormFeedback>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col md="3">
                            <Label htmlFor="text-input">Departure Latitude</Label>
                        </Col>
                        <Col xs="12" md="9">
                            <Input type="number"
                                    name="dept_lat"
                                    id="dept_lat"
                                    step="0.00001"
                                    placeholder="Departure Latitude"
                                    valid={!errors.dept_lat}
                                    invalid={touched.dept_lat && !!errors.dept_lat}
                                    // autoFocus={true}
                                    required
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.dept_lat} />
                            <FormFeedback>{errors.dept_lat}</FormFeedback>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col md="3">
                            <Label htmlFor="text-input">Departure Longitude</Label>
                        </Col>
                        <Col xs="12" md="9">
                            <Input type="number"
                                    name="dept_lng"
                                    id="dept_lng"
                                    step="0.00001"
                                    placeholder="Departure Longitude"
                                    valid={!errors.dept_lng}
                                    invalid={touched.dept_lng && !!errors.dept_lng}
                                    // autoFocus={true}
                                    required
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.dept_lng} />
                            <FormFeedback>{errors.dept_lng}</FormFeedback>
                        </Col>
                    </FormGroup>
                    
                    <FormGroup row>
                    <Col md="3">
                        <Label htmlFor="text-input">Departure Plot</Label>
                    </Col>
                    <Col xs="12" md="9">
                        <div className="text-muted">Click on the maps to set Departure<br/></div>
                        <MapWithAMarker
                            // selectedMarker={this.state.selectedMarker}
                            // markers={this.state.markers}
                            lat={values.dept_lat}
                            lng={values.dept_lng}
                            company_lat={this.props.company_lat}
                            company_lng={this.props.company_lng}
                            // onClick={e => {
                            //     var lat = e.latLng.lat() 
                            //     var lng = e.latLng.lng()
                            //     setFieldValue("dept_lat", lat)
                            //     setFieldValue("dept_lng", lng)
                            //     console.log(lat, lng)
                            // }}
                            // onClick={this.handleClick}
                            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCmIATqobqbCEU90nYtwd-PzCcj8EVOlDg&v=3.exp&libraries=geometry,drawing,places"
                            loadingElement={<div style={{ height: `100%` }} />}
                            containerElement={<div style={{ height: `200px` }} />}
                            mapElement={<div style={{ height: `100%` }} />}
                            kmlLink={ this.props.statKML? this.props.kml : ''}
                        />
                        <FormFeedback>{errors.dept_lat + ' and ' + errors.dept_lng}</FormFeedback>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col md="3">
                        <Label htmlFor="text-input">Target Type</Label>
                    </Col>
                    <Col xs="12" md="9">
                        <SelectField
                            id="target_type"
                            name="target_type"
                            label="target_type"
                            placeholder="Select target type"
                            searchable
                            options={targetTypeSelections}
                            value={values.target_type}
                            isMulti={false}
                            onChange={setFieldValue}
                            // onBlur={setFieldTouched}
                            touched={touched.target_type}
                            error={errors.target_type}
                            isClearable={true}
                            backspaceRemovesValue={true}
                            disabled={isSubmitting}
                        />
                    </Col>
                    {/* <FormFeedback>{errors.location}</FormFeedback> */}
                </FormGroup>
                <FormGroup row>
                    <Col md="3">
                        <Label htmlFor="text-input">Target Time</Label>
                    </Col>
                    <Col xs="12" md="9">
                    <Datetime
                        id="target_date"
                        name="target_date"
                        dateFormat="YYYY-MM-DD" 
                        timeFormat="HH:mm:ss"
                        isValidDate={ disabledDays }
                        disabled={isSubmitting}
                        onChange={time => {
                            setFieldValue("target_date", time);
                        }}
                    />
                        {/* <DateTime
                            id="addDepartureTime"
                            input="true"
                            dateFormat="MM-DD-YYYY" 
                            timeFormat="HH:mm:ss"
                            value={this.state.rawDepartureDate}
                            onChange={this.handleDepartureDateChange}
                            isValidDate={ disabledDays }
                            disabled={this.state.isAdding}/> */}
                    </Col>
                </FormGroup>


                {isSubmitting ? <center><BarLoader color={'#123abc'} 
                    loading={isSubmitting} 
                    /> <br /> Loading.... Please wait...</center> : <div><Button type="button" onClick={handleSubmit} size="sm" color="success" disabled={isSubmitting || !isValid}><i className="fa fa-check"></i> Submit</Button>
                    <Button type="button" onClick={() => this.toggleCloseAdd()} size="sm" color="warning" disabled={isSubmitting}><i className="fa fa-window-close"></i> Close</Button></div>}
                {/* <FormGroup>
                    <Button type="button" onClick={handleSubmit} color="primary" className="mr-1" disabled={isSubmitting || !isValid}>{isSubmitting ? 'Wait...' : 'Submit'}</Button>
                    <Button type="button" color="warning" className="mr-1" disabled={isSubmitting} onClick={this.toggleCloseAdd}>Close</Button>
                    <Button type="reset" color="danger" className="mr-1" disabled={isSubmitting} onClick={handleReset}>Reset</Button>
                </FormGroup> */}
                </form>
                )}
            />
        )
    }

    showSubmitted = () => {
        return (
            <Alert color="danger">
                Thank you for your patience! It seems you already submitted the thing. Please wait for the company to process the information that you provide! 
                <br/>
                <strong>Please do not add the same information more than once in one day! The designated company will be hard to handle those things!</strong>
            </Alert>
        )
    }

    render() {
        const { submitted } = this.state;
        return (
            <div>
                {
                    submitted ? 
                    this.showSubmitted()
                    :
                    this.viewAddWOReq()
                }
            </div>
        );
    }
}

WorkOrderForm.propTypes = propTypes;
WorkOrderForm.defaultProps = defaultProps;

export default WorkOrderForm;