import { combineReducers } from 'redux';
import { deliveryCompany } from './company.red.js';
import { deliveryValidation } from './deliveryvalidation.red.js';
import { paymentPage } from './paymentpage.red.js';
import { fetchTransporterDeliveryConfirmation } from './redTransporter-DeliveryConfirmationDetails.jsx';
import { fetchDeliveryStatusList } from './redTransporter-DeliveryStatusList.jsx';
import { fetchTransporterShareTrack } from './redTransporter-ShareTracker.jsx';

const rootReducer = combineReducers({
    transporterShareTrackStore: fetchTransporterShareTrack,
    transporterDeliveryConfirmationDetailsStore: fetchTransporterDeliveryConfirmation,
    deliveryStatusListStore: fetchDeliveryStatusList,

    deliveryCompanyStore: deliveryCompany,
    deliveryValidationStore: deliveryValidation,
    paymentPageStore: paymentPage
})

export default rootReducer;