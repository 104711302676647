import { FETCH_DELIVERY_COMPANY, RECEIVE_DELIVERY_COMPANY, FAILED_DELIVERY_COMPANY } from '../actions/company.actions'

const defaultDeliveryCompanyState = {
    deliveryCompanyData: [],
    companyImage: '',
    companyLat: 0,
    companyLng: 0,
    companyName: '',
    success: true,
    inProgress: false
}

export function deliveryCompany(state = defaultDeliveryCompanyState, action){
    switch (action.type) {
        case FETCH_DELIVERY_COMPANY:
            return ({
                deliveryCompanyData: [], 
                companyImage: '',
                companyLat: 0,
                companyLng: 0,
                companyName: '',
                success: true, 
                inProgress: true
            })
        case RECEIVE_DELIVERY_COMPANY:
            return Object.assign({}, state, {
                deliveryCompanyData: action.payload, 
                companyImage: action.payload.result[0].link_logo,
                companyName: action.payload.result[0].company,
                companyLat: action.payload.result[0].lat ? action.payload.result[0].lat : 0,
                companyLng: action.payload.result[0].lon ? action.payload.result[0].lon : 0,
                success: true, 
                inProgress: false, 
                fail: false
            })
        case FAILED_DELIVERY_COMPANY:
            return ({
                deliveryCompanyData: [], 
                companyImage: '',
                companyLat: 0,
                companyLng: 0,
                companyName: '',
                success: false, 
                inProgress: false,
            })
        default:
            return state
    }
}