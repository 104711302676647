import React, { Component } from 'react';
import axios from 'axios'
import { connect } from 'react-redux';
import { HashLoader } from 'react-spinners';
import {Card, Container, Row, Col, CardGroup, CardBody, Button, Alert } from 'reactstrap';
import { fetchDeliveryValidation } from '../../redux/actions/deliveryvalidation.actions';
import logoAutoma from '../../assets/img/brand/new-automa-logo.png'
import WorkOrderForm from './WorkOrderForm';

class WorkOrder extends Component {
    constructor(props) {
        super(props);
        this.state = { 

         };
    }

    getIpInfo = () => {
        let self = this;
        self.setState({
            loading: true,
        });

        axios.get("https://api.ipify.org/?format=json").then(response => {
            let data = response.data || {};
            self.setState({
                ip: data.ip || "",
                // country: data.country || "",
                loading: false,
            });


        }).catch((err) => {
            self.setState({
                loading: false,
            });
        });
    };

    componentDidMount = () => {
        this.props.fetchDeliveryValidation(this.props.match.params.id)
        this.getIpInfo()
    }

    updateYear = () => {
        var d = new Date();
        var n = d.getFullYear();
        return n;
    }

    showContent = () => {
        const { customerData, companyData } = this.props;
        const { ip } = this.state
        return (
            <div>
                <Alert color="primary">
                    You are about to fill in the details of your work order to be transported by {companyData.company ? companyData.company : 'our'} fleet. Please note that you cannot change the details after you submit the form! <br/>Payment links will be sent to your whatsapp account and need to be paid first before the transporter company make the move! Don't worry, Automa will keep the payment until the goods is delivered. Remember, always powered by Automa.id.
                </Alert>
                <WorkOrderForm 
                    validation_key={this.props.match.params.id}
                    id_customer={customerData.id}
                    company_lat={companyData.lat}
                    company_lng={companyData.lon}
                    ip_address={ip}
                />
            </div>
        )
    }

    render() {
        const { companyData, customerData, inProgress, success, message } = this.props;
        const { id } = this.props.match.params;
        return (
            <div>
                <div className="app flex-row align-items-center login-body"> 
                    <Container>
                        <Row className="justify-content-center">
                        <Col md="9">
                            <CardGroup>
                                <Card className="p-4">
                                    <CardBody>
                                        <img src={companyData.link_logo ? companyData.link_logo : logoAutoma} alt="Company Logo" className="automa-logo-login" style={{'maxWidth': '2em', 'height': 'auto', 'marginBottom': '2em'}}/>
                                        <br style={{'marginBottom': '2em'}}/><br style={{'marginBottom': '4em'}}/><br/>
                                        <h4 style={{'marginTop' : '2em'}}>{companyData.company ? companyData.company : 'Automa'} 3PL Work Order Submission</h4><br/>
                                        {
                                            id ? 
                                            <div>
                                                {
                                                    inProgress ? 
                                                    <center><HashLoader color={'#123abc'} loading={inProgress}/> <br /> Loading.... Please wait...</center>
                                                    :
                                                    (
                                                        success ? 
                                                        (
                                                            customerData.id ? 
                                                            <div>
                                                                {this.showContent()}
                                                            </div>
                                                            :
                                                            <Alert color="warning">
                                                                It seems you're lost on the site. We'd like to refer you to our super IoT platform <strong><a href="https://automa.id">automa.id</a></strong>
                                                            </Alert>
                                                        )
                                                        :
                                                        // <div>
                                                        //         {this.showContent()}
                                                        //     </div>
                                                        <center>Error fetching your data... (reason: {message})<br/><br/> 
                                                            <Button className="btn btn-primary" color="primary" size="md" disabled={inProgress} onClick={() => this.props.fetchDeliveryValidation(id)}>
                                                                <i className="fa fa-refresh"></i> 
                                                                Try again
                                                            </Button>
                                                        </center>
                                                    )
                                                }
                                            </div>
                                            :
                                            <Alert color="warning">
                                                It seems you're lost on the site. We'd like to refer you to our super IoT platform <strong><a href="https://automa.id">automa.id</a></strong>
                                            </Alert>
                                        }
                                        {/* {
                                            this.props.match.params.id ? 
                                                this.props.inProgress ? 
                                                <center><HashLoader color={'#123abc'} loading={this.props.inProgress}/> <br /> Loading.... Please wait...</center>
                                                : 
                                                (
                                                    this.props.success  ? 
                                                    this.checkDataShow() 
                                                    : 
                                                    <center>Error fetching your data... <br/><br/> 
                                                        <Button className="btn btn-primary" color="primary" size="md" disabled={this.props.inProgress} onClick={() => this.props.fetchTransporterShareTrack(this.props.match.params.id)}>
                                                            <i className="fa fa-refresh"></i> 
                                                            Try again
                                                        </Button>
                                                    </center>
                                                )
                                            :
                                            <Alert color="success">
                                                Your package delivery tracking link already obsolete. Please contact your supplier/logistic to verify delivery status. Thank you for trusting Automa ID as your one and only only Supply Chain and Logistics Management Solution.
                                            </Alert>
                                        } */}
                                    </CardBody>
                                    <Row>
                                        <Col md="12">
                                            <span className="pull-right">Always Powered by <a href="https://automa.id">Automa</a> &copy; {this.updateYear()}</span>
                                        </Col>
                                    </Row>
                                    
                                    </Card>
                                </CardGroup>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        inProgress: state.deliveryValidationStore.inProgress,
        success: state.deliveryValidationStore.success,
        message: state.deliveryValidationStore.message,
        companyData: state.deliveryValidationStore.companyData,
        customerData: state.deliveryValidationStore.customerData
    }
}

export default connect(mapStateToProps, {fetchDeliveryValidation})(WorkOrder);