import { FETCH_DELIVERY_VALIDATION, RECEIVE_DELIVERY_VALIDATION, FAILED_DELIVERY_VALIDATION } from '../actions/deliveryvalidation.actions'

const defaultTransporterShareTrackState = {
    deliveryValidationData: [],
    customerData: {},
    companyData: {},
    success: true,
    fail: false,
    message: ""
}

export function deliveryValidation(state = defaultTransporterShareTrackState, action){
    switch (action.type) {
        case FETCH_DELIVERY_VALIDATION:
            return ({
                deliveryValidationData: [], 
                customerData: {},
                companyData: {},
                success: true, 
                inProgress: true,
                message: ""
            })
        case RECEIVE_DELIVERY_VALIDATION:
            return Object.assign({}, state, {
                deliveryValidationData: action.payload, 
                customerData: action.payload.result.customer_data,
                companyData: action.payload.result.company_destination[0],
                success: true, 
                inProgress: false,
                message: ""
            })
        case FAILED_DELIVERY_VALIDATION:
            return ({
                deliveryValidationData: [], 
                customerData: {},
                companyData: {},
                success: false, 
                inProgress: false, 
                message: action.payload.message
            })
        default:
            return state
    }
}