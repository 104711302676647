import React, { Component } from 'react';
import * as Yup from 'yup'
import {Formik} from 'formik'
import axios from 'axios'
import {toast} from 'react-toastify'
import 'react-select/dist/react-select.css';
import Recaptcha from "react-recaptcha";
import PropTypes from 'prop-types'
import { Alert, Button, Col, FormGroup, FormFeedback, Input, Label } from 'reactstrap';
import { TRIPL_CUSTOMER_ENTRY_DATA } from '../../redux/config/links';

const propTypes = {
    id: PropTypes.string,
    companyName: PropTypes.string,
    ip: PropTypes.string
}

const defaultProps = {
    id: '',
    companyName: '',
    ip: "0.0.0.0"
}

const validationSchema = Yup.object().shape({
    name: Yup.string()
    .required('Name is required!'),
    email: Yup.string()
    // eslint-disable-next-line
    .matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, "Email should be on a valid pattern!")
    .required('Email should be stated!'),
    phone_number: Yup.string()
    .min(7, 'Mobile phone number (Whatsapp) should not be shorter than 7 characters!')
    .matches(/^[6]{1}[2]{1}[0-9]{6}/, 'Phone number should be start with 62')
    .required('Mobile phone number (Whatsapp) should be stated!'),
    company_name: Yup.string()
    .min(2, `Company Name has to be at least 2 characters`)
    .required('Company name is required!'),
    recaptcha: Yup.string().required(),
})

class CompanyFillOut extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            initialValues: {
                name: '',
                email: '',
                phone_number: '',
                company_name: '',
            },
            submitted: false,
         };
    }

    componentDidMount() {
        const script = document.createElement("script");
        script.src =
        "https://www.google.com/recaptcha/api.js";
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);
    }

    submitAnother = () => {
        this.setState({
            submitted: false
        })
    }

    showForms = () => {
        const { id, ip } = this.props;
        return (
            <div>
                <Formik
                    initialValues={this.state.initialValues}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        var self = this;
                        // var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
                        // var auth = loginCreds.token;
                        setSubmitting(true)
                        console.log(id)

                        axios({
                            method: 'post',
                            url: TRIPL_CUSTOMER_ENTRY_DATA,
                            headers: {
                                // Authorization: auth
                            },
                            data: {
                                custom_link:id,
                                name: values.name,
                                email: values.email,
                                phone: values.phone_number,
                                company_name: values.company_name,
                                recaptcha: values.recaptcha,
                                ip_address: ip
                            }
                        })
                        .then((response) => {
                            if(response.status === 200){
                                if (response.data.status === "success"){
                                    setSubmitting(false)
                                    // self.props.fetchDelivery(self.props.share_data)
                                    resetForm()
                                    self.setState({submitted: true})
                                    return toast.success('You have submitted the data successfully!')
                                }
                            }
                        })
                        .catch(function(error){
                            if (error.response) {
                                if(error.response.status === 401) {
                                    setSubmitting(false)
                                    return toast.error("Invalid credentials! Please try again!");
                                } else if (error.response.status === 403) {
                                    setSubmitting(false)
                                    return toast.error("Unauthorized Access! Please try to logout..");
                                } else if (error.response.status === 400) {
                                    setSubmitting(false)
                                    return toast.error(error.response.data.error.message);
                                } else if (error.response.status === 404 || error.response.status === 500) {
                                    setSubmitting(false)
                                    return toast.error("Server cannot be contacted! Please ask your system administrator!");
                                } else {
                                    setSubmitting(false)
                                    return toast.error('Something went wrong... Please try again later...')
                                }
                            } else if (error.request) {
                                setSubmitting(false)
                                return toast.error('Request error! Please try again later...')
                            } else {
                                setSubmitting(false)
                                return toast.error('Something went wrong... Please try again later...')
                            }
                        })
                    }}
                    validationSchema={validationSchema}
                    render={({
                    values,
                    errors,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                    handleSubmit,
                    handleBlur,
                    handleChange,
                    // handleReset,
                    // setTouched,
                    isValid,
                    isSubmitting
                    }) => (
                    <form>
                        <FormGroup row>
                            <Col md="12">
                                Please fill in all of the details:
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col md="3">
                                <Label htmlFor="text-input">Your name</Label>
                            </Col>
                            <Col md="9">
                                <Input 
                                    type="text"
                                    name="name"
                                    id="name"
                                    placeholder="Your name ex. Joe"
                                    autoComplete={false}
                                    valid={!errors.name}
                                    invalid={touched.name && !!errors.name}
                                    required
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.name} 
                                    disabled={isSubmitting}
                                />
                            <FormFeedback>{errors.name}</FormFeedback>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col md="3">
                                <Label htmlFor="text-input">Email</Label>
                            </Col>
                            <Col md="9">
                                <Input 
                                    type="email"
                                    name="email"
                                    id="email"
                                    placeholder="Email i.e john@doe.com"
                                    autoComplete={false}
                                    valid={!errors.email}
                                    invalid={touched.email && !!errors.email}
                                    required
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email} 
                                    disabled={isSubmitting}
                                />
                            <FormFeedback>{errors.email}</FormFeedback>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col md="3">
                                <Label htmlFor="text-input">Whatsapp Number</Label>
                            </Col>
                            <Col md="9">
                                <Input 
                                    type="text"
                                    name="phone_number"
                                    id="phone_number"
                                    placeholder="Whatsapp Number i.e 628123456789"
                                    autoComplete={false}
                                    valid={!errors.phone_number}
                                    invalid={touched.phone_number && !!errors.phone_number}
                                    required
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.phone_number} 
                                    disabled={isSubmitting}
                                />
                            <FormFeedback>{errors.phone_number}</FormFeedback>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col md="3">
                                <Label htmlFor="text-input">Company Name</Label>
                            </Col>
                            <Col md="9">
                                <Input 
                                    type="text"
                                    name="company_name"
                                    id="company_name"
                                    placeholder="Company name i.e ABC PTE LTD"
                                    autoComplete={false}
                                    valid={!errors.company_name}
                                    invalid={touched.company_name && !!errors.company_name}
                                    required
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.company_name} 
                                    disabled={isSubmitting}
                                />
                            <FormFeedback>{errors.company_name}</FormFeedback>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col md="3">
                                Verification
                            </Col>
                            <Col md="9" className="pull-right">
                                <Recaptcha
                                    sitekey="6Lf7RQ0bAAAAABE_TgZ4k61-l6_iY9ngc6-BkJYg"
                                    render="explicit"
                                    theme="light"
                                    size="compact"
                                    verifyCallback={(response) => { setFieldValue("recaptcha", response); }}
                                    onloadCallback={() => { console.log("done loading!"); }}
                                />
                                {errors.recaptcha 
                                && touched.recaptcha && (
                                <p>{errors.recaptcha}</p>
                                )}
                            </Col>
                        </FormGroup>
                        <FormGroup row className="pull-right">
                            
                            <Button type="button" onClick={handleSubmit} color="primary" className="mr-1" disabled={isSubmitting || !isValid}>{isSubmitting ? 'Wait...' : 'Submit'}</Button>&nbsp;&nbsp;
                            {/* <Button type="button" color="warning" className="mr-1" disabled={isSubmitting} onClick={this.toggleCloseAdd}>Close</Button> */}
                            {/* <Button type="reset" color="danger" className="mr-1" disabled={isSubmitting} onClick={handleReset}>Reset</Button> */}
                        </FormGroup>
                    </form>
                    )}
                />
            </div>
        )
    }

    showSuccess = () => {
        return (
            <div>
                <Alert color="success">
                    You have submitted your first verification for the 3PL System. 1 submission only for 1 work order. So please note that if you want to create more than 1 (one) work order, you should submit again. Click button down below to submit another inquiries
                    <br/>
                    <center><Button size="md" onClick={() => this.submitAnother()}>Submit another</Button></center>
                </Alert>
            </div>
        )
    }

    render() {
        const { submitted } = this.state;
        return (
            <div>
                {
                    submitted ? 
                    this.showSuccess()
                    :
                    this.showForms()
                }
            </div>
        );
    }
}

CompanyFillOut.propTypes = propTypes;
CompanyFillOut.defaultProps = defaultProps;

export default CompanyFillOut;
