import React, { Component } from "react";
// import { FormFeedback } from 'reactstrap'
import Select from "react-select";

class SelectField extends Component {
  handleChange = value => {
    const { onChange, name } = this.props;

    onChange(name, value);
  };

  handleBlur = () => {
    const { onBlur, name } = this.props;

    onBlur(name, true);
  };

  render() {
    const {
      id,
      // name,
      // label,
      searchable,
      placeholder,
      options,
      value,
      isMulti,
      isDisabled,
      touched,
      error,
      isClearable,
      backspaceRemovesValue
    } = this.props;

    return (
      <div>
        <Select
          id={id}
          placeholder={placeholder}
          options={options}
          value={value}
          searchable={searchable}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          touched={touched}
          error={error}
          isMulti={isMulti}
          isDisabled={isDisabled}
          isClearable={isClearable}
          backspaceRemovesValue={backspaceRemovesValue}
          components={{ ClearIndicator: null }}
        />
        
        {touched && error ? <p className="invalid-feedback" style={{'display': 'block' }}>{error}</p> : null}
      </div>
    );
  }
}

export { SelectField };
