import { FETCH_TRANSPORTER_DELIVERY_CONFIRMATION_DETAILS, RECEIVE_TRANSPORTER_DELIVERY_CONFIRMATION_DETAILS, FAILED_TRANSPORTER_DELIVERY_CONFIRMATION_DETAILS } from '../actions/aTransporter-DeliveryConfirmationDetails'

const defaultTransporterDeliveryConfirmationDetails = {
    transporterDeliveryConfirmationDetailsData: [],
    success: true,
    customerApprovalStatus: false,
    fail: false,
}

export function fetchTransporterDeliveryConfirmation(state = defaultTransporterDeliveryConfirmationDetails, action){
    switch (action.type) {
        case FETCH_TRANSPORTER_DELIVERY_CONFIRMATION_DETAILS:
            return ({transporterDeliveryConfirmationDetailsData: [], success: true, inProgress: true, fail: false, customerApprovalStatus: false})
        case RECEIVE_TRANSPORTER_DELIVERY_CONFIRMATION_DETAILS:
            var _statusApproval = false;
            if(action.payload.result.result[0].status_customer_approval === null || action.payload.result.result[0].status_customer_approval_detail === null) {
                _statusApproval = false;
            } else {
                _statusApproval = true;
            }
            return Object.assign({}, state, {transporterDeliveryConfirmationDetailsData: action.payload.result.result[0], success: true, inProgress: false, fail: false, customerApprovalStatus: _statusApproval})
        case FAILED_TRANSPORTER_DELIVERY_CONFIRMATION_DETAILS:
            return ({transporterDeliveryConfirmationDetailsData: [], success: false, inProgress: false, fail: true, customerApprovalStatus: false})
        default:
            return state
    }
}