import React, { Component } from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    CardGroup,
    Alert,
    CardBody,
    Button
} from 'reactstrap'
import { fetchPaymentPage } from '../../redux/actions/paymentpage.actions'
import { connect } from 'react-redux'
import logoAutoma from '../../assets/img/brand/new-automa-logo.png'
import { RadioGroup, RadioButton } from 'react-radio-buttons';
import axios from 'axios';
import {toast} from 'react-toastify'
import { HashLoader } from 'react-spinners';
import { TRIPL_PAYMENT_GENERATE_URL } from '../../redux/config/links';

class Payment extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            selectedPaymentMethod: "CREDIT_CARD",
            price: 0,
         };
    }

    UNSAFE_componentWillMount() {
        this.props.fetchPaymentPage(this.props.match.params.id)
    }

    componentDidMount = () => {
        setInterval(() => this.props.fetchPaymentPage(this.props.match.params.id), 180000);
    }

    handleGenerateInvoice = () => {
        this.setState({ isLoading: true })
        var self = this;
        // var loginCreds = JSON.parse(window.atob(localStorage.Automalt));
        // var auth = loginCreds.token;
        if(this.state.selectedPaymentMethod === "") {

        } else {
            axios({
                method:'post',
                url: TRIPL_PAYMENT_GENERATE_URL, //Please change when it's ready
                // headers: {
                //     Authorization: auth
                // },
                data: {
                    validation_data: self.props.match.params.id,
                    payment_methods: self.state.selectedPaymentMethod
                }
            })
            .then((response) => {
                if(response.status === 200) {
                    self.setState({
                        isLoading: false,
                        link: response.data.result.invoice_url,
                        success: true
                    })
                    if (response.data.status === "success") {
                        // toast.success('Data validated! You can proceed!')
                        window.alert("You'll be redirected to the payment page powered by xendit. Please proceed the payment there!")
                        // self.toggle()
                        window.open(self.state.link)
                    }
                }
            })
            .catch(function(error){
                if (error.response) {
                    if(error.response.status === 401) {
                        self.setState({
                            isLoading: false,
                            success: false
                        })
                        return toast.error("Invalid credentials! Please try again!");
                    } else if (error.response.status === 404 || error.response.status === 500) {
                        self.setState({
                            isLoading: false,
                            success: false
                        })
                        return toast.error("Server cannot be contacted! Please ask your system administrator!");
                    } else if (error.response.status === 400) {
                        self.setState({
                            isLoading: false,
                            success: false
                        })
                        return toast.error(error.response.data.message);
                    } else {
                        self.setState({
                            isLoading: false,
                            success: false
                        })
                        return toast.error('Something went wrong... Please try again later...');
                    }
                } else if (error.request) {
                    self.setState({
                        isLoading: false,
                        success: false
                    })
                    return toast.error('Request error! Please try again later...')
                } else {
                    self.setState({
                        isLoading: false,
                        success: false
                    })
                    return toast.error('Something went wrong... Please try again later...')
                }
            })
        }
    }

    onChange = (value) => {
        this.setState({
            selectedPaymentMethod: value
        })
        this.changePriceView(value)
    }

    changePriceView = (_pyMethod) => {
        if(_pyMethod === "CREDIT_CARD") {
            this.setState({
                price: this.props.ccPrice
            })
        } else if(_pyMethod === "QRIS") {
            this.setState({
                price: this.props.qrPrice
            })
        } else if(_pyMethod === "BANK") {
            this.setState({
                price: this.props.vaPrice
            })
        }
    }

    currencyFormatter = (cell) => {
        // eslint-disable-next-line
        if(cell == null) {
            return ""
        } else {
            return cell.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' });
        }
    }

    checkPaymentStatus = (_status) => {
        const {invoice_link} = this.props;
        const {isLoading} = this.state
        if(_status === "NOT SETUP INVOICE") {
            return (
                <Card>
                    <CardBody>
                        <Alert color="warning">
                            You'll be redirected to our payment page, powered by Xendit. Please proccess the payment there. You'll be notified once your payment has been succeded.
                        </Alert>
                        {
                            !invoice_link ? 
                            <div>
                                <strong>Select Payment Method</strong>
                                <hr/>
                                <RadioGroup onChange={ this.onChange }>
                                    <RadioButton value="QRIS">
                                        {/* <img src={companyData ? companyData.link_logo : logoAutoma} alt="Company Logo" style={{'maxWidth': '2em', 'height': 'auto'}}/> &nbsp; */}
                                        QRIS 
                                    </RadioButton>
                                    <RadioButton value="CREDIT_CARD">
                                        {/* <img src={companyData ? companyData.link_logo : logoAutoma} alt="Company Logo" style={{'maxWidth': '2em', 'height': 'auto'}}/> &nbsp; */}
                                        Credit Card
                                    </RadioButton>
                                    <RadioButton value="BANK">
                                        {/* <img src={companyData ? companyData.link_logo : logoAutoma} alt="Company Logo" style={{'maxWidth': '2em', 'height': 'auto'}}/> &nbsp; */}
                                        Virtual Account (Mandiri, BRI, BNI, Permata)
                                    </RadioButton>
                                </RadioGroup>
                                <div className="text-muted">*Price below include platform fee and other charges. DO NOT GIVE MONEY TO THE DRIVER!</div>
                            </div>
                            :
                            <div>Please click on button below to process the payment!</div>
                        }
                        
                        <hr/>
                        <Row>
                            <Col md="6">
                                <strong>Total&nbsp;</strong>
                            </Col>
                            <Col md="6">
                                {
                                    invoice_link ? 
                                    <div>You can check your total payment on the payment page. Please click button below!</div>
                                    :
                                    this.currencyFormatter(this.state.price)
                                }
                            </Col>
                        </Row><br/><br/>
                        <Row className="pull-right">
                            <Col md="12">
                                {
                                    invoice_link ? 
                                    <Button size="md" color="primary" onClick={() => window.open(invoice_link)} disabled={isLoading}>Pay Now!</Button>
                                    :
                                    <Button size="md" color="primary" onClick={() => this.handleGenerateInvoice()} disabled={isLoading}>Generate Invoice!</Button>
                                }
                                
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            )
        } else if(_status === "DONE") {
            return (
                <Card>
                    <CardBody>
                        <Alert color="success">
                            Payment successfull! Please wait for your next whatsapp message when the transporting company scheduled your delivery.
                        </Alert>
                        {/* {
                            !invoice_link ? 
                            <div>
                                <strong>Select Payment Method</strong>
                                <hr/>
                                <RadioGroup onChange={ this.onChange }>
                                    <RadioButton value="QRIS">
                                        <img src={companyData ? companyData.link_logo : logoAutoma} alt="Company Logo" style={{'maxWidth': '2em', 'height': 'auto'}}/> &nbsp;
                                        QRIS 
                                    </RadioButton>
                                    <RadioButton value="CREDIT_CARD">
                                        <img src={companyData ? companyData.link_logo : logoAutoma} alt="Company Logo" style={{'maxWidth': '2em', 'height': 'auto'}}/> &nbsp;
                                        Credit Card
                                    </RadioButton>
                                    <RadioButton value="BANK">
                                        <img src={companyData ? companyData.link_logo : logoAutoma} alt="Company Logo" style={{'maxWidth': '2em', 'height': 'auto'}}/> &nbsp;
                                        Virtual Account (Mandiri, BRI, BNI, Permata)
                                    </RadioButton>
                                </RadioGroup>
                                <div className="text-muted">*Price below include platform fee and other charges. DO NOT GIVE MONEY TO THE DRIVER!</div>
                            </div>
                            :
                            <div>Please click on button below to process the payment!</div>
                        } */}
                        
                        <hr/>
                        {/* <Row>
                            <Col md="6">
                                <strong>Total&nbsp;</strong>
                            </Col>
                            <Col md="6">
                                {
                                    invoice_link ? 
                                    <div>You can check your total payment on the payment page. Please click button below!</div>
                                    :
                                    this.currencyFormatter(this.state.price)
                                }
                            </Col>
                        </Row><br/><br/>
                        <Row className="pull-right">
                            <Col md="12">
                                {
                                    invoice_link ? 
                                    <Button size="md" color="primary" onClick={() => window.open(invoice_link)} disabled={isLoading}>Pay Now!</Button>
                                    :
                                    <Button size="md" color="primary" onClick={() => this.handleGenerateInvoice()} disabled={isLoading}>Generate Invoice!</Button>
                                }
                                
                            </Col>
                        </Row> */}
                    </CardBody>
                </Card>
            )
        } else if(_status === "WAITING PAYMENT") {
            return (
                <Card>
                    <CardBody>
                        <Alert color="warning">
                            You'll be redirected to our payment page, powered by Xendit. Please proccess the payment there. You'll be notified once your payment has been succeded.
                        </Alert>
                        {
                            !invoice_link ? 
                            <div>
                                <strong>Select Payment Method</strong>
                                <hr/>
                                <RadioGroup onChange={ this.onChange }>
                                    <RadioButton value="QRIS">
                                        {/* <img src={companyData ? companyData.link_logo : logoAutoma} alt="Company Logo" style={{'maxWidth': '2em', 'height': 'auto'}}/> &nbsp; */}
                                        QRIS 
                                    </RadioButton>
                                    <RadioButton value="CREDIT_CARD">
                                        {/* <img src={companyData ? companyData.link_logo : logoAutoma} alt="Company Logo" style={{'maxWidth': '2em', 'height': 'auto'}}/> &nbsp; */}
                                        Credit Card
                                    </RadioButton>
                                    <RadioButton value="BANK">
                                        {/* <img src={companyData ? companyData.link_logo : logoAutoma} alt="Company Logo" style={{'maxWidth': '2em', 'height': 'auto'}}/> &nbsp; */}
                                        Virtual Account (Mandiri, BRI, BNI, Permata)
                                    </RadioButton>
                                    <RadioButton value="BCA">
                                        {/* <img src={companyData ? companyData.link_logo : logoAutoma} alt="Company Logo" style={{'maxWidth': '2em', 'height': 'auto'}}/> &nbsp; */}
                                        BCA Virtual Account
                                    </RadioButton>
                                </RadioGroup>
                                <div className="text-muted">*Price below include platform fee and other charges. DO NOT GIVE MONEY TO THE DRIVER!</div>
                            </div>
                            :
                            <div>Please click on button below to process the payment!</div>
                        }
                        
                        <hr/>
                        <Row>
                            <Col md="6">
                                <strong>Total&nbsp;</strong>
                            </Col>
                            <Col md="6">
                                {
                                    invoice_link ? 
                                    <div>You can check your total payment on the payment page. Please click button below!</div>
                                    :
                                    this.currencyFormatter(this.state.price)
                                }
                            </Col>
                        </Row><br/><br/>
                        <Row className="pull-right">
                            <Col md="12">
                                {
                                    invoice_link ? 
                                    <Button size="md" color="primary" onClick={() => window.open(invoice_link)} disabled={isLoading}>Pay Now!</Button>
                                    :
                                    <Button size="md" color="primary" onClick={() => this.handleGenerateInvoice()} disabled={isLoading}>Generate Invoice!</Button>
                                }
                                
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            )
        } else if(_status === "PAYMENT NOT FULL") {

        } else {

        }
    }

    render() {
        const { company_logo, woData, payment_status, bidderData, inProgress, success } = this.props;
        // const { isLoading } = this.state;
        const { id } = this.props.match.params
        return (
            <div>
                <div className="app flex-row align-items-center login-body"> 
                    <Container>
                        <Row className="justify-content-center">
                            <Col md="9">
                                <CardGroup>
                                    <Card className="p-4">
                                        <img src={company_logo ? company_logo : logoAutoma} alt="Company Logo" className="automa-logo-login" style={{'maxWidth': '2em', 'height': 'auto', 'marginBottom': '2em'}}/>
                                        <br style={{'marginBottom': '2em'}}/><br style={{'marginBottom': '4em'}}/><br/>
                                        <h4 style={{'marginTop' : '2em'}}>{bidderData ? bidderData.company_bidder : 'Automa'} 3PL Payment Page</h4><br/>

                                        {
                                            id ? 
                                            <div>
                                                {
                                                    inProgress ? 
                                                    <center><HashLoader color={'#123abc'} loading={inProgress}/> <br /> Please wait while we check your work order and stuffs!</center>
                                                    :
                                                    (
                                                        success ? 
                                                        <div>
                                                            <Alert color="primary">
                                                                You are about to pay your delivery fee. Please select the payment method and check your amount. Your package about to be delivered by {bidderData ? bidderData.company_bidder : 'our'} fleet. Please note that you cannot cancel after payment has been received by us! <br/><br/> Don't worry, Automa will keep the payment until the goods is delivered. Remember, always powered by Automa.id.<br/><br/> <div className="text-muted">Pssst... Click button below if youalready made the payment and want to check the status of your payment</div> <hr/>
                                                                <Button disabled={this.props.inProgress} color="success" onClick={() => this.props.fetchPaymentPage(this.props.match.params.id)}><i className="fa fa-refresh"/> Check Payment</Button>
                                                            </Alert>

                                                            <Card>
                                                                <CardBody>
                                                                    <strong>Delivery Details</strong> 
                                                                    <hr/>
                                                                    <Row>
                                                                        <Col md={6}>
                                                                            <dt>Order Number</dt>
                                                                            <dd>{woData ? woData.wo_number : '-'}</dd>

                                                                            <dt>Destination Name</dt>
                                                                            <dd style={{ textAlign: 'justify' }}>{woData ? woData.wo_destination_name : '-'}</dd>

                                                                            <dt>Destination Address</dt>
                                                                            <dd style={{ textAlign: 'justify' }}>{woData ? woData.wo_address : '-'}</dd>
                                                                        </Col>
                                                                        <Col md={6}>
                                                                            <dt>Order Description</dt>
                                                                            <dd style={{ textAlign: 'justify' }}>{woData ? woData.wo_desc : '-'}</dd>

                                                                            <dt>Pickup Location</dt>
                                                                            <dd style={{ textAlign: 'justify' }}>{woData ? woData.wo_from_name : '-'}</dd>
                                                                        </Col>
                                                                    </Row>
                                                                    <Alert color="warning">
                                                                        <Row>
                                                                            <Col md={6}>
                                                                                <dt>Target Time</dt>
                                                                                <dd>{woData ? woData.target_time : '-'}</dd>
                                                                            </Col>
                                                                            <Col md={6}>
                                                                                <dt>Target Type</dt>
                                                                                <dd>{ woData ? (woData.target_type > 1 ? 'Arrived at destination by date' : 'Pickup by date') : '-'}</dd>
                                                                            </Col>
                                                                        </Row>
                                                                    </Alert>
                                                                    <hr />
                                                                    <strong>Company Notes</strong>
                                                                    <hr/>
                                                                    <Row>
                                                                        <Col md={6}>
                                                                            <dt>Bid Desc</dt>
                                                                            <dd>{bidderData ? bidderData.bid_desc : '-'}</dd>
                                                                        </Col>
                                                                        <Col md={6}>
                                                                            <dt>Payment Desc</dt>
                                                                            <dd>{bidderData ? bidderData.payment_desc : '-'}</dd>
                                                                        </Col>
                                                                    </Row>
                                                                </CardBody>
                                                            </Card>

                                                            {this.checkPaymentStatus(payment_status)}
                                                        </div>
                                                        :
                                                        <center>
                                                            <Alert color="warning">Oops! We don't see any of your details or having trouble to check your data. Click button below to try again or contact our support by clicking <a href="https://wa.me/628112995355" target="_blank" rel="noopener noreferrer">here</a><br/></Alert>
                                                            
                                                            <Button className="btn btn-primary" color="warning" size="md" disabled={this.props.inProgress} onClick={() => this.props.fetchPaymentPage(id)}>
                                                                <i className="fa fa-refresh"></i> 
                                                                Try again
                                                            </Button>
                                                        </center>
                                                    )
                                                }
                                            </div>
                                            :
                                            <Alert color="danger">
                                                <strong>Invalid 3PL Payment link!</strong> <hr/>
                                                Hi there! We strongly advise to check again your link. We can't detect any of your trucking data. 
                                            </Alert>
                                        }

                                        

                                        
                                    </Card>
                                </CardGroup>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        paymentPageData: state.paymentPageStore.paymentPageData,
        inProgress: state.paymentPageStore.inProgress,
        success: state.paymentPageStore.success,
        woData: state.paymentPageStore.woData,
        bidderData: state.paymentPageStore.bidderData,
        ccPrice: state.paymentPageStore.ccPrice,
        vaPrice: state.paymentPageStore.vaPrice,
        qrPrice: state.paymentPageStore.qrPrice,
        payment_status: state.paymentPageStore.payment_status,
        invoice_link: state.paymentPageStore.invoice_link,
        company_logo: state.paymentPageStore.company_logo
    }
}

export default connect(mapStateToProps, {fetchPaymentPage})(Payment);