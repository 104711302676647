import axios from "axios";
import * as API_LINKS from '../config/links.jsx'
import {toast} from 'react-toastify'

export const FETCH_PAYMENT_PAGE = 'FETCH_PAYMENT_PAGE'
export const RECEIVE_PAYMENT_PAGE = 'RECEIVE_PAYMENT_PAGE'
export const FAILED_PAYMENT_PAGE = 'FAILED_PAYMENT_PAGE'

export function fetchPaymentPage(_id){

    return (dispatch, getState) => {
        dispatch({type: FETCH_PAYMENT_PAGE});
        axios({
            method: 'post',
            url: API_LINKS.TRIPL_FIRST_PAYMENT_URL,
            data: {
                validation_data: _id
            }
        })
        .then((response) => {
            dispatch( {
                type: RECEIVE_PAYMENT_PAGE,
                payload: response.data
            })
            // console.log(response.data)
        })
        .catch(function(error){
            if(error.response){
                if(error.response.status === 401) {
                    dispatch({
                        type: FAILED_PAYMENT_PAGE,
                        payload: error.response.data
                    })
                    return toast.error("Unathorized access!");
                    
                } else if (error.response.status === 404 || error.response.status === 500) {
                    dispatch({
                        type: FAILED_PAYMENT_PAGE,
                        payload: error.response.data
                    })
                    return toast.error("Failed in fetching payment page. Server cannot be contacted! Please ask your system administrator!");
                } else if (error.response.status === 400) {
                    dispatch({
                        type: FAILED_PAYMENT_PAGE,
                        payload: error.response.data
                    })
                    return toast.error(error.response.data.message);
                } else {
                    dispatch({
                        type: FAILED_PAYMENT_PAGE
                    })
                    return toast.error("Please contact your system adminitrator")
                }
            }else if (error.request) {
                dispatch({
                    type: FAILED_PAYMENT_PAGE
                })
                return toast.error('Request error! Please try again later...')
            } else {
                dispatch({
                    type: FAILED_PAYMENT_PAGE
                })
                return toast.error('Something went wrong... Please try again later...')
            }

        })
    }
}