import { FETCH_TRANSPORTER_SHARE_TRACK, RECEIVE_TRANSPORTER_SHARE_TRACK, FAILED_TRANSPORTER_SHARE_TRACK } from '../actions/aTransporter-ShareTracker'

const defaultTransporterShareTrackState = {
    transporterShareTrackData: [],
    success: true,
    fail: false,
}

export function fetchTransporterShareTrack(state = defaultTransporterShareTrackState, action){
    switch (action.type) {
        case FETCH_TRANSPORTER_SHARE_TRACK:
            return ({transporterShareTrackData: [], success: true, inProgress: true, fail: false})
        case RECEIVE_TRANSPORTER_SHARE_TRACK:
            return Object.assign({}, state, {transporterShareTrackData: action.payload, success: true, inProgress: false, fail: false})
        case FAILED_TRANSPORTER_SHARE_TRACK:
            return ({transporterShareTrackData: [], success: false, inProgress: false, fail: true})
        default:
            return state
    }
}