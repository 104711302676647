import { FETCH_PAYMENT_PAGE, RECEIVE_PAYMENT_PAGE, FAILED_PAYMENT_PAGE } from '../actions/paymentpage.actions'

const defaultPaymentPageState = {
    paymentPageData: {},
    success: true,
    inProgress: false,
    message: ""
}

export function paymentPage(state = defaultPaymentPageState, action){
    switch (action.type) {
        case FETCH_PAYMENT_PAGE:
            return ({
                paymentPageData: {}, 
                success: true, 
                inProgress: true,
                message: ""
            })
        case RECEIVE_PAYMENT_PAGE:
            var _ccPrice = action.payload.result.estCCPaymentPrice
            var _qrPrice = action.payload.result.estPaymentQris
            var _vaPrice = action.payload.result.estPaymentBank
            var _payStatus = action.payload.result.payment_status_desc

            return Object.assign({}, state, {
                paymentPageData: action.payload.result,
                woData: action.payload.result.work_req[0],
                bidderData: action.payload.result.bid[0],
                company_logo: action.payload.result.link_logo,
                invoice_link: action.payload.result.invoice_link,
                vaPrice: _vaPrice,
                ccPrice: _ccPrice,
                qrPrice: _qrPrice,
                payment_status: _payStatus,
                success: true, 
                inProgress: false,
                message: ""
            })
        case FAILED_PAYMENT_PAGE:
            return ({
                paymentPageData: {}, 
                success: false, 
                inProgress: false, 
                message: action.payload.message
            })
        default:
            return state
    }
}