import React, { Component } from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import { Provider } from "react-redux";
import './App.css';
// Styles
// CoreUI Icons Set
import '@coreui/icons/css/coreui-icons.min.css';
// Import Flag Icons Set
import 'flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
import 'font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
import 'simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
import './scss/style.css'

// Containers
// import { DefaultLayout } from './containers';

//Redux Store Initialization
import store from './redux/store.js'

//Import Dashboard
// import Dashboard from './views/Dashboard/Dashboard'
// import TransporterTracker from './views/Transporter/Tracker/Tracker.jsx';
// import TransporterTrackerA from './views/Transporter/Tracker/TrackerDetails.jsx';
// import DeliveryConfirmation from './views/Transporter/DeliveryConfirmation/DeliveryConfirmation.jsx';
// import DeliveryConfirmationID from './views/Transporter/DeliveryConfirmation/ID-id/DeliveryConfirmation';
import Company from './views/Company/Company'
import WorkOrder from './views/WorkOrder/WorkOrder'
import Payment from './views/Payments/Payment'
import Lost from './views/Lost/Lost';

// import { renderRoutes } from 'react-router-config';

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <HashRouter>
          <Switch>
            {/* <Route path="/transporter/:id" name="Transporter Tracker" component={TransporterTrackerA}  exact={true}/>
            <Route path="/transporter" name="Transporter Tracker" component={TransporterTrackerA}  exact={true}/>
            <Route path="/delivery/:id" name="Transporter Delivery Conformation" component={DeliveryConfirmation}  exact={true}/>
            <Route path="/delivery" name="Transporter Delivery Conformation" component={DeliveryConfirmation}  exact={true}/>

            <Route path="/delivery-id/:id" name="Transporter Delivery Conformation" component={DeliveryConfirmationID}  exact={true}/>
            <Route path="/delivery-id/" name="Transporter Delivery Conformation" component={DeliveryConfirmationID}  exact={true}/> */}
            <Route path="/c/:id" name="3PL System Website" component={Company} exact={true} />
            {/* <Route path="/c" name="3PL System Website" component={Company} exact={true} /> */}

            <Route path="/wo/:id" name="3PL WO Submission" component={WorkOrder} exact={true} />
            {/* <Route path="/wo" name="3PL WO Submission" component={WorkOrder} exact={true} /> */}

            <Route path="/payment/:id" name="3PL Payment" component={Payment} exact={true} />

            <Route path="/404" name="Automa 404" component={Lost} exact={true} />

            <Redirect from="/" to="/404" />
            {/* <Route path="/transporter/:id" name="Transporter Tracker" component={TransporterTracker} /> */}
            {/* <Route path="/" name="Home" component={DefaultLayout} /> */}
            {/* <Route path="/" name="Dashboard" component={Dashboard} /> */}
          </Switch>
        </HashRouter>
      </Provider>
    );
  }
}

export default App;
